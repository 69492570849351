require('./src/components/reset.css');
require('./src/components/swiper.min.css');
require('./src/components/bootstrap-grid.min.css');
require('./src/components/slick.css');
require('./src/static/facebook-pixel.js');
const {initDatadogRum} = require('./src/utils/init-datadog-rum');

if (!('IntersectionObserver' in window)) {
  // eslint-disable-next-line global-require
  require('intersection-observer');
  // eslint-disable-next-line global-require
  require('custom-event-polyfill');
}

exports.onClientEntry = () => {
  initDatadogRum({service: 'career-web'});
};
